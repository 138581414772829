.header {
	background-color: $color-background-dim;

	header {
		@extend %menu_default;
		padding: 10px 0;

		.logo {
			display: inline-block;

			img {
				padding-right: 20px;
				border-right: 1px solid $gray89;
			}
			span {
				padding: 10px 0 10px 20px;
			}
			@include at-most($screen-sm-max) {
				& {
					span {
						font-size: 14px;
					}
				}
			}
			@include at-most($screen-xs-max) {
				& {
					display: inline-block;

					img {
						border: none;
						max-width: 100%;
						padding: 0px;
					}
					span {
						display: block;
						padding: 0px;
					}
				}
			}
		}
		.login-container {
			text-align: right;
			padding-top: 5px;
			.login {
				display: inline-block;
				margin: 10px 0;

				span {
					padding-right: 10px;
				}
			}

			@include at-most($screen-xs-max) {
				& {
					border-left: 1px solid $gray89;
				}
			}
		}

	}
}
