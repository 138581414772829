.learn {
	padding: 80px 0;

	p {
		span {
			color: $text-color;
		}

		a {
			font-size: $text_size_secondary;
			font-style: italic;
			letter-spacing: 1px;
			text-decoration: underline;
		}
	}

	img {
		float: left;
		margin: 0 30px 30px 0;
		max-width: 100%;
	}

	@include at-most($screen-sm-max) {
		& {
			padding: 20px 0;

			img {
				max-width: 40%;
			}

			p {
				display: inline-block;
			}
		}
	}

	@include at-most($screen-xs-max) {
		& {
			img {
				margin: 0;
			}
		}
	}
}
