@charset "UTF-8";

@import "website/utils/_utils";

@import "website/base/_fontfaces";
@import "website/base/_variables";
@import "website/base/_stock-club-variables";


@import "common/bootstrap/_variables";
@import "common/bootstrap/_mixins";
@import "common/bootstrap/_tooltip";
@import "common/bootstrap/_grid";
@import "common/bootstrap/_normalize";
@import "common/bootstrap/_scaffolding";
@import "common/bootstrap/_responsive-utilities";

@import "website/utils/slick";
@import "website/utils/slick-theme";

@import "website/base/_texts";
@import "website/base/_buttons";
@import "website/layout/_default";

@import "website/layout/_header";
@import "website/layout/_footer";

@import "website/components/_slider";
@import "website/components/_brands";
@import "website/components/_tracking";
@import "website/components/_learn";
@import "website/components/_contact";


//==============================================================================
// STOCK CLUB - OVERRIDES
//==============================================================================

.menu-wrapper .main-bar .logo {
	width: 127px;
	height: 28px;
	margin-top: 46px;
	background-image: url('../img/stock-club-logo.png');
}

.menu-wrapper .mobile-menu-utils .logo {
	width: 127px;
	height: 28px;
	background-image: url('../img/stock-club-logo.png');
}

.menu-wrapper .main-bar .menu-item + .menu-item {
	border-top-color: $color-accent-secondary;
}

.data-table td.selected {
	background: $blue90;
}
