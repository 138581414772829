
//==============================================================================
// COLOR DEFS
//==============================================================================
	$blue90: #cfe5ef;
	$blue75: #50b1db;
	$blue57: #0094d4;

//==============================================================================
// COLOR USAGE
//==============================================================================

	$color-text-primary: $gray20;
	$color-text-secondary: $white;
	$color-text-faded: $gray60;

	$color-accent-primary: $blue57;
	$color-accent-primary-hover: darken($color-accent-primary, 10%);
	$color-accent-secondary: $blue75;
	$color-accent-secondary-hover: $color-accent-primary;
