%title_1_default {
	font-size: $title_1_size;
	font-weight: $normal;
	margin: 10px 0;
	color: $white;

    @include at-most($screen-md-max) {
        font-size: $title_1_medium_size;
    }
}

%title_2_default {
	font-size: $title_2_size;
	font-weight: $medium;
	// color: $color-text-primary;

	span {
		text-transform: uppercase;
	}

	@include at-most($screen-md-max) {
        font-size: $title_2_medium_size;
    }
}

%title_3_default {
	font-size: $title_3_size;
	text-transform: uppercase;
	font-weight: $bold;

	span {
		font-weight: $fw-black;
		color: $color-accent-secondary;
	}
    @include at-most($screen-md-max) {
        font-size: $title_3_medium_size;
    }
}

%title_4_default {
	font-size: $title_4_size;
	font-weight: $bold;
	color: $color-accent-secondary;

	@include at-most($screen-md-max) {
		font-size: $title_4_medium_size;
	}
}

h1 {
    @extend %title_1_default;
}

h2 {
    @extend %title_2_default;
}

h3 {
    @extend %title_3_default;
}

h4 {
    @extend %title_4_default;
}

* {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%menu_default {
    font-size: $text_size_secondary;
    font-weight: normal;
    text-decoration: none;
    color: $text_color;
}

%text_default {
	font-family: $font-family;
    font-size: $text_size;
	color: $color-text-primary;

	@include at-most($screen-sm-max) {
		font-size: $text_size_small;
	}
}

%button_default {
    font-size: $button_text_size;
    font-weight: $bold;
	text-transform: uppercase;
    text-decoration: none;
	background-color: $color-accent-primary;
	display: inline-block;

	a{
		display: inline-block;
		padding: 15px 30px;
		color: $white;
	}
}

body {
    font-family: $font-family;
    color: $text_color;
    @extend %text_default;
}

a{
    color: $text_color;
    text-decoration:none;
    transition: color ease 0.3s;

    &:hover {
        color: $color-accent-primary-hover;
        text-decoration:none;
    }
    &:focus {
        color: $text_color;
        text-decoration: none;
    }
}

p {
    @extend %text_default;

	span {
		text-transform: uppercase;
		color: $color-accent-secondary;
		font-weight: $bold;
	}
}

ul {
	list-style: url('../img/canoptec/check.png');

	&.stockclub {
		list-style: url('../img/stockclub/check-stock.png');
	}
}
