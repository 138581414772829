[class^="button"] {
	@extend %button_default;
	cursor: pointer;
	transition: background-color ease 0.3s;

	&:hover {
		background-color: $color-accent-primary-hover;

		a {
			color: $white;
		}
	}

	&[class*="-start"] {

		a {
			padding: 0px;

			span {
				font-size: $text_size;
				display: inline-block;
				background-color: $color-accent-secondary;
				padding: 18px 30px;
				transition: background-color ease 0.3s;
			}
			i {
				font-size: 30px;
				display: inline-block;
				padding: 18px 30px;
				transition: background-color ease 0.3s;
			}

			&:hover {
				span {
					background-color: $color-accent-primary;
				}
			}

			@include at-most($screen-md-max) {
				& {
					span {
						font-size: 16px;
					}
					i {
						font-size: 18px;
					}
				}

			}
		}


	}

	&[class*="-flat"] {
		background-color: $white;

		a {
			color: $color-accent-secondary;
		}
	}
}
