.brands {
	background: url('../img/round-bg.jpg') center center no-repeat;
	text-align: center;
	padding: 50px 0;

	img {
		padding: 0 30px;
		max-width: 100%;
	}
	h3 {
		a {
			text-decoration: underline;
			color: $color-accent-secondary;
			letter-spacing: 2px;
		}
	}
	@include at-most($screen-md-max) {
		& {
			background-image: none;
			background-color: #f7f7f7;
		}

		img {
			padding: 0 20px;
			max-width: 40%;
		}
	}

	@include at-most($screen-sm-max) {
		& {
			padding: 20px 0;
		}

		img {
			padding: 0px;
			display: block;
			margin: 0 auto;
		}
	}
}
