.slider {
	position: relative;

	.sliderQuotes {
		position: absolute;
			top: 50%;
			left: 0;
		transform: translate(0, -50%);
		width: 100%;

		@include at-most($screen-md-max) {
			.sliderQuotes-container {
				font-size: $text_size_small;
				padding: 40px;
			}
		}

		@include at-most($screen-sm-max) {
			width: 100%;
			top: inherit;
			position: relative;
			transform: translate(0,0);
			padding: 20px 0 60px 0;

			.sliderQuotes-container {
				padding: 0px;
			}
		}
	}
}
