.contactUs {
	.contact {
		padding: 30px 0;
		p {
			color: $white;
			margin: 0px;
		}

		p.note {
			font-size: $text_size_secondary;
		}

		.button-flat {
			margin-top: 15px;

			&:hover {
				background-color: $color-accent-primary-hover;
				a {
					color: $white;
				}
			}
		}
	}

	@include at-most($screen-sm-max) {
		& {
			background-color: $color-accent-primary;
		}
	}

}
