// Colors
$black: #000000;
$white: #ffffff;

$gray97: #f7f7f7;
$gray95: #f0f0f0;
$gray92: #e9e9e9;
$gray89: #e0e0e0;
$gray60: #909090;
$gray44: #676767;
$gray25: #3b3b3b;
$gray20: #303030;

// Section Colors
$red50: #d33f45;
$red39: #a6302c;
$red27: #74221f;

$color-text-primary: $gray20;
$color-text-secondary: $white;
$color-text-faded: $gray60;

$color-accent-primary: $red39;
$color-accent-primary-hover: $red27;
$color-accent-secondary: $red50;

$color-background-light: $white;
$color-background-dim: $gray95;
$color-background-dark: $gray25;

// Section TEXTS
$font-family: 'Roboto', sans-serif;

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$fw-black: 900;

$title_1_size: 40px;
$title_2_size: 30px;
$title_3_size: 24px;
$title_4_size: 22px;

$title_1_medium_size: 30px;
$title_2_medium_size: 22px;
$title_3_medium_size: 20px;
$title_4_medium_size: 18px;

$text_size: 30px;
$text_size_small: 20px;

$text_size_secondary: 18px;

$button_text_size: 14px;
$button_text_large_size: 25px;

// Breakpoints
$screen-lg: 1301px;
$screen-md-max: 1300px;
