.tracking {
	background: url('../img/men-bg.jpg') top center no-repeat;
	background-size: cover;
	padding: 110px 0;

	p {
		margin-bottom: 10av0px;
	}
	.package {
		text-align: center;
		display: inline-block;
		padding: 0 30px;
	}
	.icons {
		display: inline-block;
	}

	@include at-most($screen-sm-max) {
		& {
			padding: 20px 0;

			p {
				text-align: center;
			}
			.icons-container {
				text-align: center;
			}
		}
	}

	@include at-most($screen-xs-max) {
		& {
			.icons {
				// display: block;
			}
			.package {
				padding: 0 10px;
				img {
					max-width: 70%;
				}
			}
		}
	}
}
